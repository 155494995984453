/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { useHistory, useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ContactModalContent } from './components/contact-modal-content';
import {
	createContactAction,
	deleteContactAction,
	getContactInfoAction,
	resetStateAction,
	updateContactAction,
} from './actions';
import {
	getActiveAddressIdSelector,
	getActiveContactIdSelector,
	getContactInfoSelector,
	getContactNameSelector,
	getIsAuditTabShownSelector,
	getIsContactAddFormOpenSelector,
	getIsContactEditFormOpenSelector,
	getIsContactInfoLoadingSelector,
	getIsModalShownSelector,
} from './selectors';
import { CONTACT_FORM_NAME } from './constants';
import { resetStateAction as resetAddressModalStateAction } from '../address-modal/actions';
import { resetStateAction as resetAddressesStateAction } from '../addresses/actions';
import { getIsAvailableContactsButtonsSelector } from '../profile/selectors';
import { setWindowInfoAction } from '../alert-modal/actions';
import { Audit } from '../../components/audit';

const ContactModalWrapper = ({
	onGetContactInfo,
	isContactInfoLoading,
	isContactEditFormOpen,
	isContactAddFormOpen,
	contactInfo,
	contactName,
	onResetState,
	onDeleteContact,
	isModalShown,
	activeContactId,
	onCreateContact,
	onUpdateContact,
	onFormSubmit,
	activeAddressId,
	onResetAddressModalState,
	onResetAddressesState,
	isAvailable,
	onSetWindowInfo,
	isAuditTabShown,
}) => {
	if (!isModalShown) return null;
	useInjectSaga({ key: 'contactModalSaga', saga });
	useInjectReducer({ key: 'contactModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const modal = query.get('modal');

	const mode = query.get('mode');

	const history = useHistory();

	useEffect(() => {
		return () => onResetState();
	}, []);

	useEffect(() => {
		if (activeContactId && activeAddressId) {
			onGetContactInfo({ addressId: activeAddressId, contactId: activeContactId });
		}
	}, [activeContactId, activeAddressId]);

	const handleContactFormOpenButtonClick = mode => {
		if (activeContactId) {
			query.set('contactId', activeContactId);
		} else {
			query.delete('contactId');
		}

		if (mode === 'edit') {
			query.set('mode', 'edit');
		}

		if (mode === 'cancel') {
			query.delete('mode');
		}

		if (mode === 'cancel' && !activeContactId) {
			onResetState();
			onResetAddressModalState();
			query.set('modal', 'contacts');
		}

		history.push(`${pathname}?${query}`);
	};

	const handleModalClose = () => {
		query.delete('modal');
		query.delete('mode');
		query.delete('id');
		query.delete('contactId');
		query.delete('addressId');
		query.delete('tab');

		onResetState();
		onResetAddressModalState();
		if (pathname.includes('partners')) {
			onResetAddressesState();
		}
		history.push(`${pathname}?${query}`);
	};

	const handleContactModalClose = () => {
		query.delete('contactId');
		query.delete('tab');
		query.set('modal', 'contacts');

		onResetState();
		onResetAddressModalState();
		history.push(`${pathname}?${query}`);
	};

	const handleContactDelete = () => {
		onSetWindowInfo({
			type: 'delete',
			title: 'Вы уверены?',
			text: 'Вы не сможете это отменить',
			button: {
				type: 'success',
				text: 'Да, удалить',
				onClick: () =>
					onDeleteContact({
						contactId: activeContactId,
						addressId: activeAddressId,
						redirect: () => {
							query.delete('contactId');
							query.set('modal', 'contacts');
							onResetState();
							history.push(`${pathname}?${query}`);
						},
					}),
			},
		});
	};

	const handleContactFormSubmit = async () => {
		await onFormSubmit(CONTACT_FORM_NAME);

		const query = new URLSearchParams(search);
		query.delete('contactId');
		query.delete('mode');

		if (activeContactId) {
			onUpdateContact({
				contactId: activeContactId,
				addressId: activeAddressId,
				redirect: () => handleContactFormOpenButtonClick('cancel'),
				searchQuery: query.toString(),
			});
			return;
		}

		onCreateContact({
			redirect: () => handleContactFormOpenButtonClick('cancel'),
			resetState: onResetState,
			addressId: activeAddressId,
			searchQuery: query.toString(),
		});
	};

	const handleNavItemClick = modal => {
		if (modal === 'audit') {
			query.set('tab', 'audit');
		} else {
			query.set('modal', modal);
			query.delete('tab');
		}

		if (mode) {
			query.delete('mode');
		}

		if (modal === 'address') {
			onResetState();
		}

		if (modal === 'contacts') {
			onResetAddressModalState();
		}
		history.push(`${pathname}?${query}`);
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleContactFormSubmit();
		}
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText={
				isContactEditFormOpen || isContactAddFormOpen
					? `${isContactEditFormOpen ? 'Редактор контактного лица' : 'Новое контактное лицо'}`
					: contactName
			}
			navItems={[
				{
					id: 'info',
					text: 'Информация',
					onClick: () => handleNavItemClick('contact'),
					isActive: modal === 'contact' && !isAuditTabShown,
				},
				{
					id: 'audit',
					text: 'Изменения',
					onClick: () => handleNavItemClick('audit'),
					isActive: isAuditTabShown,
				},
			]}
			modalContent={
				!isAuditTabShown ? (
					<ContactModalContent
						contactInfo={contactInfo}
						isContactInfoLoading={isContactInfoLoading}
						isContactFormOpen={isContactEditFormOpen || isContactAddFormOpen}
					/>
				) : (
					<Audit entityName={`addresses/${activeAddressId}/contacts`} entityId={query.get('contactId')} />
				)
			}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'profile' }]}
			footerButtons={
				<ModalFooterButtons
					leftButtons={
						!(isContactEditFormOpen || isContactAddFormOpen) && isAvailable.delete && !isAuditTabShown
							? [
									{
										onClick: handleContactDelete,
										text: 'Удалить',
										color: 'warning',
										disabled: isContactInfoLoading,
									},
							  ]
							: []
					}
					rightButtons={
						isContactEditFormOpen || isContactAddFormOpen
							? [
									{
										onClick: handleContactFormSubmit,
										text: 'Сохранить',
										color: 'primary',
										disabled: isContactInfoLoading,
									},
									{
										onClick: () => handleContactFormOpenButtonClick('cancel'),
										text: 'Отмена',
										color: 'secondary',
										disabled: isContactInfoLoading,
									},
							  ]
							: [
									...(isAvailable.update && !isAuditTabShown
										? [
												{
													onClick: () => handleContactFormOpenButtonClick('edit'),
													text: 'Изменить',
													color: 'primary',
													disabled: isContactInfoLoading,
												},
										  ]
										: []),
									{
										onClick: handleContactModalClose,
										text: 'Закрыть',
										color: 'secondary',
									},
							  ]
					}
				/>
			}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	contactInfo: getContactInfoSelector(),
	isContactInfoLoading: getIsContactInfoLoadingSelector(),
	isContactAddFormOpen: getIsContactAddFormOpenSelector(),
	isContactEditFormOpen: getIsContactEditFormOpenSelector(),
	contactName: getContactNameSelector(),
	isModalShown: getIsModalShownSelector(),
	activeContactId: getActiveContactIdSelector(),
	activeAddressId: getActiveAddressIdSelector(),
	isAvailable: getIsAvailableContactsButtonsSelector(),
	isAuditTabShown: getIsAuditTabShownSelector(),
});

const mapDispatchToProps = {
	onGetContactInfo: getContactInfoAction,
	onDeleteContact: deleteContactAction,
	onResetState: resetStateAction,
	onResetAddressModalState: resetAddressModalStateAction,
	onResetAddressesState: resetAddressesStateAction,
	onCreateContact: createContactAction,
	onUpdateContact: updateContactAction,
	onFormSubmit: submit,
	onSetWindowInfo: setWindowInfoAction,
};

const ContactModal = connect(mapStateToProps, mapDispatchToProps)(ContactModalWrapper);

ContactModalWrapper.propTypes = {
	onGetContactInfo: PropTypes.func.isRequired,
	contactInfo: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]).isRequired,
		}),
	),
	isContactInfoLoading: PropTypes.bool.isRequired,
	isContactEditFormOpen: PropTypes.bool.isRequired,
	isContactAddFormOpen: PropTypes.bool.isRequired,
	contactName: PropTypes.string.isRequired,
	onDeleteContact: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
	onResetAddressModalState: PropTypes.func.isRequired,
	onResetAddressesState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	activeContactId: PropTypes.string,
	activeAddressId: PropTypes.string,
	onCreateContact: PropTypes.func.isRequired,
	onUpdateContact: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	isAvailable: PropTypes.objectOf(PropTypes.bool),
	onSetWindowInfo: PropTypes.func.isRequired,
	isAuditTabShown: PropTypes.bool.isRequired,
};

export default ContactModal;
