/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, reset, submit } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { useHistory, useLocation } from 'react-router';
import { ClaimFormView } from './claim-form-view';
import {
	getClaimThemesSelector,
	getClaimCategoriesSelector,
	getClaimPrioritiesSelector,
	getIsThemesLoadingSelector,
	getIsCategoriesLoadingSelector,
	getIsPrioritiesLoadingSelector,
	getIsCreateClaimModalShownSelector,
	isClaimLoadingSelector,
	getClaimFormThemeIdSelector,
	getClaimFormValuesSelector,
} from '../../selectors';
import { ModalWindow } from '../../../../semantic-ui/components/modal-window';
import ModalFooterButtons from '../../../../semantic-ui/components/modal-window/modal-footer-buttons';
import {
	createClaimAction,
	getClaimCategoriesAction,
	getClaimPrioritiesAction,
	getClaimThemesAction,
	resetClaimFormAction,
} from '../../actions';
import { validateClaimForm } from '../../utils';
import { CLAIM_FORM_NAME, REQUIRED_FIELDS_CLAIM_FORM } from '../../constants';

export const ClaimFormWrapper = ({
	themes,
	categories,
	priorities,
	isThemesLoading,
	isCategoriesLoading,
	isPrioritiesLoading,
	isCreateClaimModalShown,
	onCreateClaim,
	onGetClaimThemes,
	onGetClaimCategories,
	onGetClaimPriorities,
	onResetForm,
	isClaimLoading,
	claimFormThemeId,
	onFormSubmit,
	claimFormValues,
	onResetClaimForm,
}) => {
	if (!isCreateClaimModalShown) return null;
	const history = useHistory();

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	useEffect(() => {
		onGetClaimThemes();
		onGetClaimPriorities();
	}, []);

	const handleCloseModal = () => {
		query.set('modal', 'reqs_details');
		history.push(`${pathname}?${query}`);
		onResetClaimForm();
		onResetForm(CLAIM_FORM_NAME);
	};

	const handleCreateClaim = async () => {
		await onFormSubmit(CLAIM_FORM_NAME);

		const hasAllRequiredFields = REQUIRED_FIELDS_CLAIM_FORM.every(
			field => claimFormValues && claimFormValues[field],
		);

		if (!hasAllRequiredFields) {
			return;
		}
		query.set('modal', 'reqs_details');
		onCreateClaim({ history, query, pathname, closeModal: handleCloseModal });
	};

	useEffect(() => {
		if (claimFormThemeId) {
			onGetClaimCategories({ themeId: claimFormThemeId });
		}
	}, [claimFormThemeId]);

	return (
		<ModalWindow
			isModalShown={isCreateClaimModalShown}
			headerText="Создание претензии"
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleCloseModal }]}
			modalContent={
				<ClaimFormView
					isClaimLoading={isClaimLoading}
					themes={themes}
					categories={categories}
					priorities={priorities}
					isThemesLoading={isThemesLoading}
					isCategoriesLoading={isCategoriesLoading}
					isPrioritiesLoading={isPrioritiesLoading}
				/>
			}
			footerButtons={
				<ModalFooterButtons
					leftButtons={[]}
					rightButtons={[
						{
							color: 'primary',
							text: 'Cохранить',
							onClick: handleCreateClaim,
						},
						{
							color: 'secondary',
							text: 'Отмена',
							onClick: handleCloseModal,
						},
					]}
				/>
			}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	themes: getClaimThemesSelector(),
	categories: getClaimCategoriesSelector(),
	priorities: getClaimPrioritiesSelector(),
	isThemesLoading: getIsThemesLoadingSelector(),
	isCategoriesLoading: getIsCategoriesLoadingSelector(),
	isPrioritiesLoading: getIsPrioritiesLoadingSelector(),
	isCreateClaimModalShown: getIsCreateClaimModalShownSelector(),
	claimFormThemeId: getClaimFormThemeIdSelector(),
	claimFormValues: getClaimFormValuesSelector(),
	isClaimLoading: isClaimLoadingSelector(),
});

const mapDispatchToProps = {
	onGetClaimThemes: getClaimThemesAction,
	onGetClaimCategories: getClaimCategoriesAction,
	onGetClaimPriorities: getClaimPrioritiesAction,
	onResetForm: reset,
	onCreateClaim: createClaimAction,
	onFormSubmit: submit,
	onResetClaimForm: resetClaimFormAction,
};

ClaimFormWrapper.propTypes = {
	themes: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.number.isRequired,
			value: PropTypes.number.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	categories: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.number.isRequired,
			value: PropTypes.number.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	priorities: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.number.isRequired,
			value: PropTypes.number.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	isThemesLoading: PropTypes.bool.isRequired,
	isCategoriesLoading: PropTypes.bool.isRequired,
	isPrioritiesLoading: PropTypes.bool.isRequired,
	isCreateClaimModalShown: PropTypes.bool.isRequired,
	onCreateClaim: PropTypes.func.isRequired,
	onGetClaimThemes: PropTypes.func.isRequired,
	onGetClaimCategories: PropTypes.func.isRequired,
	onGetClaimPriorities: PropTypes.func.isRequired,
	claimFormValues: PropTypes.objectOf({
		theme_id: PropTypes.number.isRequired,
		category_id: PropTypes.number.isRequired,
		priority_id: PropTypes.number.isRequired,
		description: PropTypes.string.isRequired,
	}),
	onResetForm: PropTypes.func.isRequired,
	isClaimLoading: PropTypes.bool.isRequired,
	claimFormThemeId: PropTypes.number.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	onResetClaimForm: PropTypes.func.isRequired,
};

const ClaimForm = connect(
	mapStateToProps,
	mapDispatchToProps,
)(
	reduxForm({
		form: 'claimForm',
		onSubmit: validateClaimForm,
	})(ClaimFormWrapper),
);

export { ClaimForm };
