/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { useHistory, useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import {
	getWayNameSelector,
	getIsWayAddFormOpenSelector,
	getIsWayEditFormOpenSelector,
	getIsModalShownSelector,
	getAddressesOptionsSelector,
	getAddressesSearchValueSelector,
	getFormValuesSelector,
	getActiveWayIdSelector,
	getIsWayInfoLoadingSelector,
	getIsAuditTabShownSelector,
} from './selectors';
import {
	createWayAction,
	deleteWayAction,
	getWayInfoAction,
	resetStateAction,
	updateWayAction,
	setAddressesSearchValueAction,
	getModesAction,
} from './actions';
import { getDictionaryDataAction } from '../dictionaries/periods/actions';
import { getDictionaryDataSelector } from '../dictionaries/periods/selectors';
import { WaysInfo } from './components/way-info';
import { WayFormContainer } from './components/way-form';
import { FORM_NAME } from './constants';
import { getIsAvailableWaysButtonsSelector } from '../profile/selectors';
import { setWindowInfoAction } from '../alert-modal/actions';
import { removeModalQuery } from '../../utils/removeModalQuery';
import { Audit } from '../../components/audit';

const WaysModalWrapper = ({
	isWayEditFormOpen,
	isWayAddFormOpen,
	wayName,
	onResetState,
	onDeleteWay,
	isModalShown,
	activeWayId,
	onCreateWay,
	onUpdateWay,
	formValues,
	onGetPeriodsData,
	periodsData,
	onGetWayInfo,
	onFormSubmit,
	onGetModes,
	isAvailable,
	isWayInfoLoading,
	onSetWindowInfo,
	isAuditTabShown,
}) => {
	if (!isModalShown) return null;
	useInjectSaga({ key: 'waysModalSaga', saga });
	useInjectReducer({ key: 'waysModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		if (query.get('modal') === 'way') {
			onGetModes();
		}

		if (activeWayId) {
			onGetWayInfo(activeWayId);
		}
	}, [activeWayId]);

	useEffect(() => {
		if (formValues.is_regular && periodsData.length === 0) {
			onGetPeriodsData();
		}
	}, [formValues.is_regular]);

	const handleWayFormOpenButtonClick = mode => {
		if (activeWayId) {
			query.set('id', activeWayId);
		} else {
			query.delete('id');
		}

		if (mode === 'edit') {
			query.set('mode', 'edit');
		}

		if (mode === 'cancel') {
			query.delete('mode');
		}

		if (mode === 'cancel' && !activeWayId) {
			onResetState();
			query.delete('modal');
		}

		history.push(`${pathname}?${query}`);
	};

	const handleModalClose = () => {
		query.delete('modal');
		query.delete('mode');
		query.delete('id');
		query.delete('tab');

		onResetState();
		history.push(`${pathname}?${query}`);
	};

	const handleNavItemClick = tab => {
		if (tab === 'info') {
			query.delete('tab');
		}

		if (tab === 'audit') {
			query.set('tab', 'audit');
		}

		history.push(`${pathname}?${query}`);
	};

	const handleWayDelete = () => {
		onSetWindowInfo({
			type: 'delete',
			title: 'Вы уверены?',
			text: 'Вы не сможете это отменить',
			button: {
				type: 'success',
				text: 'Да, удалить',
				onClick: () => onDeleteWay({ id: activeWayId, redirect: handleModalClose }),
			},
		});
	};

	const handleWayFormSubmit = async () => {
		await onFormSubmit(FORM_NAME);
		if (activeWayId) {
			onUpdateWay({
				id: activeWayId,
				redirect: () => handleWayFormOpenButtonClick('cancel'),
				searchQuery: removeModalQuery(query.toString()),
			});
			return;
		}

		onCreateWay({ redirect: handleModalClose, searchQuery: removeModalQuery(query.toString()) });
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleWayFormSubmit();
		}
	};

	const isWayFormOpen = useMemo(() => isWayEditFormOpen || isWayAddFormOpen, [isWayAddFormOpen, isWayEditFormOpen]);

	const rightButtons = useMemo(() => {
		return isWayFormOpen
			? [
					{
						onClick: handleWayFormSubmit,
						text: 'Сохранить',
						color: 'primary',
						disabled: isWayInfoLoading,
					},
					{
						onClick: () => handleWayFormOpenButtonClick('cancel'),
						text: 'Отмена',
						color: 'secondary',
						disabled: isWayInfoLoading,
					},
			  ]
			: [
					...(isAvailable.update && !isAuditTabShown
						? [
								{
									onClick: () => handleWayFormOpenButtonClick('edit'),
									text: 'Изменить',
									color: 'primary',
									disabled: isWayInfoLoading,
								},
						  ]
						: []),
					{
						onClick: handleModalClose,
						text: 'Закрыть',
						color: 'secondary',
					},
			  ];
	}, [isWayFormOpen, query, isAvailable.update]);

	const leftButtons = useMemo(() => {
		return !isWayFormOpen && isAvailable.delete && !isAuditTabShown
			? [
					{
						onClick: handleWayDelete,
						text: 'Удалить',
						color: 'warning',
						disabled: isWayInfoLoading,
					},
			  ]
			: [];
	}, [isWayFormOpen, query, isAvailable.delete]);

	const headerText = useMemo(() => {
		return isWayEditFormOpen || isWayAddFormOpen
			? `${isWayEditFormOpen ? 'Редактор маршрута' : 'Новый маршрут'}`
			: wayName;
	}, [isWayEditFormOpen, isWayAddFormOpen, wayName]);

	const navItems = useMemo(() => {
		return isWayAddFormOpen || isWayEditFormOpen
			? []
			: [
					{
						id: 'info',
						text: 'Информация',
						onClick: () => handleNavItemClick('info'),
						isActive: !isAuditTabShown,
					},
					{
						id: 'audit',
						text: 'Изменения',
						onClick: () => handleNavItemClick('audit'),
						isActive: isAuditTabShown,
					},
			  ];
	}, [isWayAddFormOpen, isWayEditFormOpen, handleNavItemClick, isAuditTabShown]);

	const modalContent = useMemo(() => {
		if (isWayFormOpen) {
			return <WayFormContainer />;
		}

		return !isAuditTabShown ? <WaysInfo /> : <Audit entityName="ways" entityId={activeWayId} />;
	}, [isWayFormOpen, isAuditTabShown]);

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText={headerText}
			navItems={navItems}
			modalContent={modalContent}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'way' }]}
			footerButtons={<ModalFooterButtons leftButtons={leftButtons} rightButtons={rightButtons} />}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	isWayAddFormOpen: getIsWayAddFormOpenSelector(),
	isWayEditFormOpen: getIsWayEditFormOpenSelector(),
	wayName: getWayNameSelector(),
	activeWayId: getActiveWayIdSelector(),
	isModalShown: getIsModalShownSelector(),
	addressesOptions: getAddressesOptionsSelector(),
	addressesSearchValue: getAddressesSearchValueSelector(),
	formValues: getFormValuesSelector(),
	periodsData: getDictionaryDataSelector(),
	isAvailable: getIsAvailableWaysButtonsSelector(),
	isWayInfoLoading: getIsWayInfoLoadingSelector(),
	isAuditTabShown: getIsAuditTabShownSelector(),
});

const mapDispatchToProps = {
	onGetWayInfo: getWayInfoAction,
	onDeleteWay: deleteWayAction,
	onResetState: resetStateAction,
	onCreateWay: createWayAction,
	onUpdateWay: updateWayAction,
	onAddressSearchChange: setAddressesSearchValueAction,
	onGetPeriodsData: getDictionaryDataAction,
	onFormSubmit: submit,
	onGetModes: getModesAction,
	onSetWindowInfo: setWindowInfoAction,
};

const WaysModal = connect(mapStateToProps, mapDispatchToProps)(WaysModalWrapper);

WaysModalWrapper.propTypes = {
	isWayEditFormOpen: PropTypes.bool.isRequired,
	isWayAddFormOpen: PropTypes.bool.isRequired,
	wayName: PropTypes.string.isRequired,
	onDeleteWay: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	onCreateWay: PropTypes.func.isRequired,
	onUpdateWay: PropTypes.func.isRequired,
	isWayInfoLoading: PropTypes.bool.isRequired,
	activeWayId: PropTypes.string,
	onGetWayInfo: PropTypes.func.isRequired,
	addressesOptions: PropTypes.objectOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.string.isRequired,
				value: PropTypes.string.isRequired,
				text: PropTypes.string,
				data: PropTypes.shape({
					title: PropTypes.string.isRequired,
					address: PropTypes.string.isRequired,
					deleted: PropTypes.bool,
				}),
			}),
		),
	).isRequired,
	onAddressSearchChange: PropTypes.func.isRequired,
	addressesSearchValue: PropTypes.object.isRequired,
	formValues: PropTypes.object.isRequired,
	onGetPeriodsData: PropTypes.func.isRequired,
	periodsData: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			id: PropTypes.number.isRequired,
		}),
	),
	onFormSubmit: PropTypes.func.isRequired,
	onGetModes: PropTypes.func.isRequired,
	isAvailable: PropTypes.objectOf(PropTypes.bool),
	onSetWindowInfo: PropTypes.func.isRequired,
	isAuditTabShown: PropTypes.bool.isRequired,
};

export default WaysModal;
