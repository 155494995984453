/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { reduxForm, reset } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { ModalWindow } from '../../../../semantic-ui/components/modal-window';
import ModalFooterButtons from '../../../../semantic-ui/components/modal-window/modal-footer-buttons';
import { getSelectSolveClaimAction, solveClaimAction } from '../../actions';
import { getFormClaimsSolutionValuesSelector, getIsClaimSolveModalShownSelector } from '../../selectors';
import { useInjectSaga } from '../../../../utils/injectSaga';
import { useInjectReducer } from '../../../../utils/injectReducer';
import saga from '../../saga';
import reducer from '../../reducer';
import { RefusalReasonFormView } from '../../../refusal-reason-modal/components/refusal-reason-form';
import closeModal from '../../../../utils/closeModal';
import { CLAIMS_SOLUTION_FORM_NAME } from '../../constants';

const ClaimsSolutionFormWrapper = ({ onSolveClaim, isModalShown, onResetForm, formValues, onGetSelectSolveClaim }) => {
	if (!isModalShown) return null;
	useInjectSaga({ key: 'claimsDetailsModalSaga', saga });
	useInjectReducer({ key: 'claimsDetailsModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		onGetSelectSolveClaim({ req_id: query.get('req_id'), id: query.get('id') });
	}, []);

	const handleClose = () => {
		closeModal(query);
		history.push(`${pathname}?${query}`);
		onResetForm(CLAIMS_SOLUTION_FORM_NAME);
	};

	const handleBack = () => {
		query.set('modal', 'claims_details');
		history.push(`${pathname}?${query}`);
		onResetForm(CLAIMS_SOLUTION_FORM_NAME);
	};

	const handleSave = () => {
		onSolveClaim({ id: query.get('id'), req_id: query.get('req_id'), body: formValues, redirect: handleBack });
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			headerText="Решение"
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleClose }]}
			modalContent={
				<RefusalReasonFormView title="Решение" name="solution" placeholder="Текст решения претензии" rows={6} />
			}
			footerButtons={
				<ModalFooterButtons
					rightButtons={[
						{
							text: 'Сохранить',
							color: 'primary',
							type: 'submit',
							onClick: handleSave,
						},
						{
							text: 'Закрыть',
							color: 'secondary',
							onClick: handleBack,
						},
					]}
				/>
			}
		/>
	);
};

ClaimsSolutionFormWrapper.propTypes = {
	onSolveClaim: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	onResetForm: PropTypes.func.isRequired,
	onGetFormValues: PropTypes.func.isRequired,
	formValues: PropTypes.object.isRequired,
	onGetSelectSolveClaim: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
	isModalShown: getIsClaimSolveModalShownSelector(),
	formValues: getFormClaimsSolutionValuesSelector(),
});

const mapDispatchToProps = {
	onSolveClaim: solveClaimAction,
	onResetForm: reset,
	onGetSelectSolveClaim: getSelectSolveClaimAction,
};

const ClaimsSolutionForm = connect(mapStateToProps, mapDispatchToProps)(ClaimsSolutionFormWrapper);

export default reduxForm({
	form: CLAIMS_SOLUTION_FORM_NAME,
	enableReinitialize: true,
})(ClaimsSolutionForm);
