/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import { initialState } from './reducer';
import { FILTER_FORM_NAME, OPTIONS_KEYS_NAMES, OPTIONS_KEYS_VALUES } from './constants';
import { getFieldOptions } from './utils';

export const selectReqsFilterDomain = state => state.reqsFilterReducer || initialState;
export const selectRouterDomain = state => state.router || {};
export const selectProfileModalDomain = state => state.profileModalReducer || {};

export const getAccordionIndexSelector = () =>
	createSelector(selectReqsFilterDomain, ({ accordionIndex }) => accordionIndex);

export const getAnalyticsAccordionIndexSelector = () =>
	createSelector(selectReqsFilterDomain, ({ analyticsAccordionIndex }) => analyticsAccordionIndex);

export const getFilterOptionsSelector = () =>
	createSelector(selectReqsFilterDomain, ({ filterOptions }) => {
		return Object.entries(filterOptions).reduce((result, [key, value]) => {
			if (!value || !OPTIONS_KEYS_NAMES[key]) {
				return result;
			}

			const fieldOptions = getFieldOptions(OPTIONS_KEYS_VALUES[key], OPTIONS_KEYS_NAMES[key], value);

			return {
				...result,
				[key]: ['to, exec'].includes(key)
					? fieldOptions
					: [{ key: 'all', text: 'Все', value: 'all' }, ...fieldOptions],
			};
		}, {});
	});

export const getShowBidCheckboxSelector = () =>
	createSelector(selectRouterDomain, selectProfileModalDomain, ({ location }, { userInfo }) => {
		const companyTypeId = userInfo?.company?.type?.id;

		// For company type 2 (Customer)
		if (companyTypeId === 2) {
			const validCustomerPaths = [
				'/reqs/combined/my',
				'/reqs/combined/approval',
				'/reqs/combined/completed',
				'/reqs/car/my',
				'/reqs/car/approval',
				'/reqs/car/completed',
				'/reqs/express/my',
				'/reqs/express/approval',
				'/reqs/express/completed',
				'/reqs/avia/my',
				'/reqs/avia/approval',
				'/reqs/avia/completed',
			];

			return validCustomerPaths.includes(location.pathname);
		}

		// For company type 3 (Transport Company)
		if (companyTypeId === 3) {
			const validTCPaths = [
				'/reqs/combined',
				'/reqs/combined/completed',
				'/reqs/car',
				'/reqs/car/completed',
				'/reqs/express',
				'/reqs/express/completed',
				'/reqs/avia',
				'/reqs/avia/completed',
			];

			return validTCPaths.includes(location.pathname);
		}

		return false;
	});

export const getIsFilterClearButtonViewSelector = () =>
	createSelector(getFormValues(FILTER_FORM_NAME), formValues => Boolean(formValues) && !isEmpty(formValues));

export const getIsAnalyticsClearButtonViewSelector = () =>
	createSelector(selectReqsFilterDomain, ({ analytics }) => Boolean(analytics?.some(item => item.isChecked)));

export const getAnalyticsSelector = () => createSelector(selectReqsFilterDomain, ({ analytics }) => analytics || []);

export const getShowRelationshipCheckboxSelector = () =>
	createSelector(
		[state => state.router?.location?.pathname, state => state.profileModalReducer?.userInfo?.company?.type?.id],
		(pathname, userCompanyType) => {
			if (userCompanyType !== 3) {
				return true;
			}

			// List of invalid paths where checkbox should not be shown
			const invalidPaths = [
				'/reqs/combined',
				'/reqs/combined/auction',
				'/reqs/combined/tender',
				'/reqs/car',
				'/reqs/car/auction',
				'/reqs/car/tender',
				'/reqs/express',
				'/reqs/express/auction',
				'/reqs/express/tender',
				'/reqs/avia',
				'/reqs/avia/auction',
				'/reqs/avia/tender',
			];

			const isPathInvalid = !invalidPaths.some(path => pathname === path);

			return userCompanyType === 3 && isPathInvalid;
		},
	);
