import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { notificationInit } from '../../modules/notifications/actions';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { getContactsRequest, updateContactDefaultRequest } from '../../api/requests';
import {
	GET_CONTACTS,
	UPDATE_CONTACT_DEFAULT,
	setContactsAction,
	setIsContactsLoadingAction,
	setPaginationAction,
} from './actions';
import { DISMISS_TIME } from '../../api/constants';
import { getContactsSelector } from './selectors';
import { errorHandler } from '../../api/utils';

export function* getContactsSaga({ payload: { searchQuery, id } }) {
	try {
		yield put(setIsContactsLoadingAction(true));

		const str = new URLSearchParams(searchQuery);
		str.delete('page');

		const { data, message, meta, toast } = yield call(
			getContactsRequest,
			str.toString().replace('search', '').replace('contactsSearch', 'search'),
			id,
		);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const query = new URLSearchParams(searchQuery);
			query.set('modal', 'contact');

			yield put(
				setContactsAction(
					data?.map(contact => ({
						...contact,
						name: `${contact.last_name || ''} ${contact.first_name || ''} ${contact.middle_name || ''}`,
						email: contact.email || '-',
						link: `${window.location.pathname}?${query.toString() ? `${query.toString()}&` : ''}contactId=${
							contact.id
						}`,
					})),
				),
			);

			if (meta) {
				yield put(
					setPaginationAction({
						current_page: meta.current_page,
						per_page: meta.per_page,
						from: meta.from,
						last_page: meta.last_page,
						total: meta.total,
					}),
				);
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsContactsLoadingAction(false));
	}
}

export function* updateContactDefaultSaga({ payload: { addressId, contactId, value, searchQuery } }) {
	try {
		const { data, message, toast } = yield call(
			updateContactDefaultRequest,
			{ is_default: value },
			addressId,
			contactId,
		);

		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}` } : {}),
					}),
				);
			}

			const contacts = yield select(getContactsSelector());

			yield put(
				setContactsAction(
					contacts.map(contact => {
						return contact.id === data.id
							? { ...contact, is_default: data.is_default }
							: { ...contact, is_default: data.is_default ? false : contact.is_default };
					}),
				),
			);
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	}
}

export default function* contactsSaga() {
	yield takeEvery(GET_CONTACTS, getContactsSaga);
	yield takeEvery(UPDATE_CONTACT_DEFAULT, updateContactDefaultSaga);
}
