/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm, change } from 'redux-form';
import { useHistory, useLocation } from 'react-router';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { ReqWrapper } from './components/req-wrapper';
import {
	getIsReqsLoadingSelector,
	getPaginationSelector,
	getReqsSelector,
	getIsTimerVisibleSelector,
	getPathnameSelector,
	getShowExportButtonSelector,
	getReqsSelectedIdsSelector,
} from './selectors';
import { deleteReqAction, getReqsAction, setReqsAction, takeReqAction, updateReqAction } from './actions';
import { BUTTONS_TO_MODALS, BUTTONS_TO_ROUTES, FORM_NAME } from './constants';
import { getFromStorage } from '../../components/sidebar/utils';
import { getIsAvailableReqsButtonsSelector } from '../profile/selectors';
import { removeModalQuery } from '../../utils/removeModalQuery';
import { setWindowInfoAction } from '../alert-modal/actions';
import { scrollToTop } from '../reqs-filter/utils';
import { redirectToExport } from './utils';

const RequestsListContainerWrapper = ({
	reqs,
	onGetReqs,
	onUpdateReq,
	pagination,
	onDeleteReq,
	onTakeReq,
	isReqsLoading,
	isAvailable,
	onSetWindowInfo,
	onSetReqs,
	isTimerVisible,
	onReqsChange,
	showExportButton,
	selectedIds,
}) => {
	useInjectSaga({ key: 'requestsListContainerSaga', saga });
	useInjectReducer({ key: 'requestsListContainerReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	const id = window.location.pathname.split('/').at(-1);

	const data = getFromStorage('user_data');

	useEffect(() => {
		// грузоотправитель
		if (data?.company_type === 'shipper') {
			if (id === 'combined' || id === 'reqs') {
				history.push('/reqs/combined/my');
				return;
			}
			if (id === 'car') {
				history.push('/reqs/car/my');
				return;
			}
			if (id === 'express') {
				history.push('/reqs/express/my');
				return;
			}
			if (id === 'avia') {
				history.push('/reqs/avia/my');
				return;
			}
		}
		// грузоперевозчик
		if (data?.company_type === 'carrier') {
			if (id === 'reqs') {
				history.push('/reqs/combined');
				return;
			}
		}

		if (window.location.pathname === '/home/information') {
			return;
		}

		onGetReqs({ id: window.location.pathname, searchQuery: removeModalQuery(query.toString()) });
	}, [window.location.pathname]);

	const handleActionButtonClick = (field, id) => {
		if (BUTTONS_TO_MODALS[field]) {
			query.set('modal', BUTTONS_TO_MODALS[field]);
			query.set('id', id);

			if (field === 'recreate') {
				query.set('mode', 'recreate');
			}

			history.push(`${pathname}?${query}`);
			return;
		}

		if (field === 'destroy') {
			const path = pathname.split('/').slice(0, 3).join('/');

			onSetWindowInfo({
				type: 'delete',
				title: 'Вы уверены?',
				text: 'Вы не сможете это отменить',
				button: {
					type: 'success',
					text: 'Да, удалить',
					onClick: () => onDeleteReq({ id, pathname: path }),
				},
			});
			return;
		}

		if (['close', 'revoke'].includes(field)) {
			onSetWindowInfo({
				type: 'delete',
				title: 'Вы уверены?',
				text: 'Вы не сможете это отменить',
				button: {
					type: 'success',
					text: field === 'revoke' ? 'Да, отозвать' : 'Да, аннулировать',
					onClick: () => onUpdateReq({ field, id, searchQuery: query.toString() }),
				},
			});
			return;
		}

		if (field === 'take') {
			onTakeReq({ id, searchQuery: query.toString() });
			return;
		}

		if (field === 'bet') {
			const req = reqs.find(req => req.id === id);

			if (req) {
				onUpdateReq({
					field: BUTTONS_TO_ROUTES[field] ? BUTTONS_TO_ROUTES[field] : field,
					id,
					searchQuery: query.toString(),
					body: { sum: req.auction?.sum, date: req.auction?.date },
				});
			}

			return;
		}

		onUpdateReq({
			field: BUTTONS_TO_ROUTES[field] ? BUTTONS_TO_ROUTES[field] : field,
			id,
			searchQuery: query.toString(),
		});
	};

	const handleKeyDown = (e, reqId) => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleActionButtonClick('bet', reqId);
		}
	};

	const handlePageChange = (_, { activePage }) => {
		query.set('page', activePage);
		history.push(`${pathname}?${query}`);

		onGetReqs({ id: window.location.pathname, searchQuery: query.toString(), scrollToTop });
	};

	const handleSelectChange = (_, { value }) => {
		query.set('page', 1);
		if (value === 'all') {
			query.delete('per_page');
			history.push(`${pathname}?${query}`);
		} else {
			query.set('per_page', value);
			history.push(`${pathname}?${query}`);
		}

		onGetReqs({ id: window.location.pathname, searchQuery: query.toString(), scrollToTop });
	};

	const handleRequestModalOpen = () => {
		query.set('modal', 'request');
		query.set('mode', 'add');

		history.push(`${pathname}?${query}`);
	};

	const handleAuctionInfoChange = (value, field, reqId) => {
		const newReqs = reqs.map(req => {
			if (req.id === reqId) {
				return { ...req, auction: { ...req.auction, [field]: value } };
			}

			return req;
		});

		onSetReqs(newReqs);
	};

	const isCreateButtonVisible =
		data?.company_type === 'shipper' && isAvailable?.create && window.location.pathname.includes('my');

	const handleExport = () => {
		redirectToExport(selectedIds);
	};

	const handleSelectAllReqs = () => {
		onReqsChange(
			FORM_NAME,
			'check',
			reqs.reduce((acc, req) => ({ ...acc, [req.id]: true }), {}),
		);
	};

	const handleDeselectAllReqs = () => {
		onReqsChange(
			FORM_NAME,
			'check',
			reqs.reduce((acc, req) => ({ ...acc, [req.id]: false }), {}),
		);
	};

	useEffect(() => {
		return () => {
			handleDeselectAllReqs();
		};
	}, [window.location.pathname]);

	return (
		<ReqWrapper
			reqs={reqs}
			isReqsLoading={isReqsLoading}
			onAuctionInfoChange={handleAuctionInfoChange}
			onModalOpen={isCreateButtonVisible ? handleRequestModalOpen : null}
			onActionButtonClick={handleActionButtonClick}
			onKeyDown={handleKeyDown}
			isTimerVisible={isTimerVisible}
			onExport={handleExport}
			showExportButton={showExportButton}
			onSelectAll={handleSelectAllReqs}
			onDeselectAll={handleDeselectAllReqs}
			pagination={
				pagination
					? {
							...pagination,
							onPageChange: handlePageChange,
							onSelectChange: handleSelectChange,
					  }
					: null
			}
		/>
	);
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
})(RequestsListContainerWrapper);

RequestsListContainerWrapper.propTypes = {
	reqs: PropTypes.arrayOf(PropTypes.object).isRequired,
	onGetReqs: PropTypes.func.isRequired,
	onUpdateReq: PropTypes.func.isRequired,
	pagination: PropTypes.shape({
		currentPage: PropTypes.number,
		totalPages: PropTypes.number.isRequired,
		total: PropTypes.number,
		from: PropTypes.number,
		to: PropTypes.number,
		isNextItem: PropTypes.bool,
		isPrevItem: PropTypes.bool,
		isFirstItem: PropTypes.bool,
		isLastItem: PropTypes.bool,
	}),
	onDeleteReq: PropTypes.func.isRequired,
	onTakeReq: PropTypes.func.isRequired,
	isReqsLoading: PropTypes.bool.isRequired,
	isAvailable: PropTypes.objectOf(PropTypes.bool),
	onSetWindowInfo: PropTypes.func.isRequired,
	onSetReqs: PropTypes.func.isRequired,
	isTimerVisible: PropTypes.bool.isRequired,
	showExportButton: PropTypes.bool.isRequired,
	onReqsChange: PropTypes.func.isRequired,
	selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = createStructuredSelector({
	reqs: getReqsSelector(),
	pagination: getPaginationSelector(),
	isReqsLoading: getIsReqsLoadingSelector(),
	isAvailable: getIsAvailableReqsButtonsSelector(),
	isTimerVisible: getIsTimerVisibleSelector(),
	getPathnameSelector: getPathnameSelector(),
	showExportButton: getShowExportButtonSelector(),
	selectedIds: getReqsSelectedIdsSelector(),
});

const mapDispatchToProps = {
	onGetReqs: getReqsAction,
	onUpdateReq: updateReqAction,
	onDeleteReq: deleteReqAction,
	onTakeReq: takeReqAction,
	onSetWindowInfo: setWindowInfoAction,
	onSetReqs: setReqsAction,
	onReqsChange: change,
};

const RequestsListContainer = connect(mapStateToProps, mapDispatchToProps)(withForm);

export default RequestsListContainer;
