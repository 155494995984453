/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Fragment } from 'react';
import './styles.less';
import { Field, FieldArray } from 'redux-form';
import PropTypes from 'prop-types';
import { FormSelectField } from '../../../../../semantic-ui/components/form-select-field';
import { FormInputField } from '../../../../../semantic-ui/components/form-input-field';
import { PriceIcon, KgIcon, VolumeIcon, PieceIcon } from '../../../../../components/text-icon';
import { FormTextAreaField } from '../../../../../semantic-ui/components/form-textarea-field';
import { FormCheckboxField } from '../../../../../semantic-ui/components/form-checkbox-field';
import { CustomDatePicker } from '../../../../../semantic-ui/components/custom-datepicker';
import { SizeComponent } from '../../size-component';
import { normalizeNumber } from '../../../../../utils/normalizeNumber';
import WeightIcon from '../../../../../components/icons/weight-icon.svg';
import CountIcon from '../../../../../components/icons/count-icon.svg';
import SizeIcon from '../../../../../components/icons/size-icon.svg';
import { normalizeInteger } from '../../../../../utils/normalizeInteger';
import { ErrorComponent } from '../../../../../components/error-component';

export const RequestFormView = ({
	partners,
	onPartnerChange,
	addresses,
	onAddressChange,
	contacts,
	types,
	rates,
	modes,
	features,
	tenderTypes,
	tenderCompanies,
	isPrr,
	info,
	onPartnerSearchChange,
	partnerSearchValue,
	onAddPartnerButtonClick,
	onAddAddressButtonClick,
	onAddContactButtonClick,
	onContactChange,
	onPointChange,
	onAddressSearchChange,
	addressSearchValue,
	onContactSearchChange,
	contactSearchValue,
	onSizeChange,
	onSizeDelete,
	isValidationCheckboxDisabled,
	onTypeChange,
	isToPlaces,
	isAvailablePartners,
	isAvailableAddresses,
	isAvailableContacts,
	isMyCarrierSelected,
}) => {
	return (
		<div className="req-form">
			<section className="req-form__section">
				<h5 className="req-form__header-text">Информация о заявке</h5>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Тип ТЭО</span>
					<div className="req-form__field">
						<Field
							name="tender_type_id"
							type="text"
							component={FormSelectField}
							autoComplete="off"
							placeholder="Выберите тендер"
							options={tenderTypes}
							loading={false}
							isWithoutSelectOnBlur
						/>
					</div>
				</div>
				{isMyCarrierSelected && (
					<>
						<div className="req-form__row">
							<span className="req-form__text req-form__text_right">Транспортная компания</span>
							<div className="req-form__field">
								<Field
									name="tender_tk_id"
									type="text"
									component={FormSelectField}
									autoComplete="off"
									placeholder="Выберите транспортную компанию"
									options={tenderCompanies}
									loading={false}
									isWithoutSelectOnBlur
									info={info.tender}
								/>
							</div>
						</div>
						<div className="req-form__row">
							<span className="req-form__text req-form__text_right">Стоимость доставки</span>
							<div className="req-form__field">
								<Field
									name="tender_amount"
									type="text"
									component={FormInputField}
									autoComplete="off"
									icon={PriceIcon}
									placeholder="Укажите стоимость доставки"
								/>
							</div>
						</div>
					</>
				)}
			</section>
			<section className="req-form__section">
				<h5 className="req-form__header-text">Информация о грузоотправителе</h5>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Грузоотправитель</span>
					<div
						className={`req-form__field req-form__field_${isAvailablePartners ? 'with' : 'without'}-button`}
					>
						<div className="req-form__field-wrapper">
							<Field
								name="points.0.partner.id"
								type="text"
								component={FormSelectField}
								autoComplete="off"
								placeholder="Выберите грузоотправителя"
								options={partners[0]}
								loading={false}
								noResultsMessage="Грузоотправители не найдены"
								search
								onChange={value => onPartnerChange(value, 0)}
								onSearchChange={value => onPartnerSearchChange(value, 0)}
								searchValue={partnerSearchValue[0]}
								isWithoutSelectOnBlur
								info={info.partner[0]}
							/>
						</div>
						{isAvailablePartners && (
							<div className="req-form__add-button" onClick={() => onAddPartnerButtonClick(0)} />
						)}
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Адрес</span>
					<div
						className={`req-form__field req-form__field_${
							isAvailableAddresses ? 'with' : 'without'
						}-button`}
					>
						<div className="req-form__field-wrapper">
							<Field
								name="points.0.address.id"
								type="text"
								component={FormSelectField}
								autoComplete="off"
								placeholder="Выберите адрес"
								options={addresses[0]}
								loading={false}
								noResultsMessage={
									partnerSearchValue[0] ? 'Адреса не найдены' : 'Выберите грузоотправителя'
								}
								onChange={value => onAddressChange(value, 0)}
								search
								onSearchChange={value => onAddressSearchChange(value, 0)}
								searchValue={addressSearchValue[0]}
								isWithoutSelectOnBlur
								info={info.address[0]}
							/>
						</div>
						{isAvailableAddresses && (
							<div className="req-form__add-button" onClick={() => onAddAddressButtonClick(0)} />
						)}
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Дата отгрузки</span>
					<div className="req-form__field req-form__date-field req-form__date-field_with-time">
						<Field
							name="points.0.date"
							type="text"
							component={CustomDatePicker}
							autoComplete="off"
							onChange={value => onPointChange(value, 0, 'date')}
							placeholder=". . / . . / . ."
							dateFormat={['dd.MM.yyyy', 'dd-MM-yyyy', 'dd/MM/yyyy']}
						/>
						<div className="req-form__time-fields">
							<div className="req-form__time-fields-wrapper">
								<span className="req-form__text req-form__text_compact">Время отгрузки</span>
								<div className="req-form__field req-form__field_with-time">
									<Field
										name="points.0.time_from"
										type="time"
										component={FormInputField}
										autoComplete="off"
										placeholder="00:00"
										isWithoutErrorText
									/>
								</div>
								<span className="req-form__time-fields-separator">-</span>
								<div className="req-form__field req-form__field_with-time">
									<Field
										name="points.0.time_to"
										type="time"
										component={FormInputField}
										autoComplete="off"
										placeholder="00:00"
										isWithoutErrorText
									/>
								</div>
							</div>
							<div className="req-form__row__error">
								<Field name="points.0.time_from" component={ErrorComponent} />
								<Field name="points.0.time_to" component={ErrorComponent} />
							</div>
						</div>
					</div>
				</div>

				<div className="req-form__row">
					<span className="req-form__text req-form__text_right" />
					<div className="req-form__checkbox">
						<Field name="is_unchanged_fetch_at" component={FormCheckboxField} toggle type="checkbox" />
					</div>
					<span className="req-form__label">Неизменная дата</span>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Контактное лицо</span>
					<div
						className={`req-form__field req-form__field_${isAvailableContacts ? 'with' : 'without'}-button`}
					>
						<div className="req-form__field-wrapper">
							<Field
								name="points.0.contact.id"
								type="text"
								component={FormSelectField}
								autoComplete="off"
								placeholder="Выберите контактное лицо"
								options={contacts[0]}
								loading={false}
								noResultsMessage={addressSearchValue[0] ? 'Контакты не найдены' : 'Выберите адрес'}
								onChange={value => onContactChange(value, 0)}
								search
								onSearchChange={value => onContactSearchChange(value, 0)}
								searchValue={contactSearchValue[0]}
								isWithoutSelectOnBlur
								info={info.contact[0]}
							/>
						</div>
						{isAvailableContacts && (
							<div className="req-form__add-button" onClick={() => onAddContactButtonClick(0)} />
						)}
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right" />
					<div className="req-form__checkbox">
						<Field name="is_prr" component={FormCheckboxField} toggle type="checkbox" />
					</div>
					<span className="req-form__label">Требуются погрузочно разгрузочные работы (ПРР)</span>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Примечание к ПРР</span>
					<div className="req-form__field">
						<Field
							name="description"
							type="text"
							component={FormTextAreaField}
							autoComplete="off"
							placeholder="Укажите примечание к ПРР"
							width="100%"
							resize="vertical"
							disabled={!isPrr.isPrr}
						/>
					</div>
				</div>
			</section>
			<section className="req-form__section">
				<h5 className="req-form__header-text">Информация о грузе</h5>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Товарная накладная №</span>
					<div className="req-form__field">
						<Field
							name="product_invoice"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите товарную накладную"
						/>
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Тариф доставки</span>
					<div className="req-form__field">
						<Field
							name="rate"
							type="text"
							component={FormSelectField}
							autoComplete="off"
							search
							placeholder="Тариф доставки"
							options={rates}
							loading={false}
							noResultsMessage=""
							isWithoutSelectOnBlur
						/>
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Характер груза</span>
					<div className="req-form__field">
						<Field
							name="type"
							type="text"
							component={FormSelectField}
							autoComplete="off"
							search
							placeholder="Характер груза"
							options={types}
							loading={false}
							noResultsMessage=""
							isWithoutSelectOnBlur
							onChange={onTypeChange}
						/>
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Температурный режим</span>
					<div className="req-form__field">
						<Field
							name="mode"
							type="text"
							component={FormSelectField}
							autoComplete="off"
							search
							placeholder="Температурный режим"
							options={modes}
							loading={false}
							noResultsMessage=""
							isWithoutSelectOnBlur
							info={info.mode}
						/>
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right" />
					<div className="req-form__field req-form__field_with-checkbox">
						<div className="req-form__checkbox-wrapper">
							<div className="req-form__checkbox">
								<Field
									name="is_thermo_check"
									component={FormCheckboxField}
									toggle
									type="checkbox"
									disabled={isValidationCheckboxDisabled}
								/>
							</div>
							<span className="req-form__checkbox-label">Наличие Термочека</span>
						</div>
						<div className="req-form__checkbox-wrapper">
							<div className="req-form__checkbox">
								<Field
									name="is_validated"
									component={FormCheckboxField}
									toggle
									type="checkbox"
									disabled={isValidationCheckboxDisabled}
								/>
							</div>
							<span className="req-form__checkbox-label">Наличие Валидации</span>
						</div>
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Заявленная стоимость груза</span>
					<div className="req-form__field">
						<Field
							name="cost"
							type="text"
							normalize={normalizeNumber}
							component={FormInputField}
							autoComplete="off"
							icon={PriceIcon}
							placeholder="Укажите заявленную стоимость груза"
						/>
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right" />
					<div className="req-form__checkbox">
						<Field
							name="is_to_places"
							component={FormCheckboxField}
							toggle
							type="checkbox"
							defaultValue={Boolean(1)}
						/>
					</div>
					<span className="req-form__label">Габаритные характеристики по местам</span>
				</div>
				{isToPlaces ? (
					<FieldArray name="size" component={SizeComponent} props={{ onSizeChange, onSizeDelete }} />
				) : (
					<div className="req-form__row">
						<span className="req-form__text req-form__text_right">Габаритные характеристики</span>
						<div className="req-form__row-with-icons">
							<div className="req-form__column">
								<div className="req-form__cargo-field">
									<img src={WeightIcon} alt="weight-icon" />
									<div className="req-form__field_small">
										<Field
											name="weight"
											type="text"
											normalize={normalizeNumber}
											component={FormInputField}
											autoComplete="off"
											icon={KgIcon}
											placeholder="Масса"
											isWithoutErrorText
										/>
									</div>
								</div>
							</div>
							<div className="req-form__column">
								<div className="req-form__cargo-field">
									<img src={SizeIcon} alt="weight-icon" />
									<div className="req-form__field_small">
										<Field
											name="volume"
											type="text"
											normalize={normalizeNumber}
											component={FormInputField}
											autoComplete="off"
											icon={VolumeIcon}
											placeholder="Объем"
											isWithoutErrorText
										/>
									</div>
								</div>
							</div>
							<div className="req-form__column">
								<div className="req-form__cargo-field">
									<img src={CountIcon} alt="count-icon" />
									<div className="req-form__field_small">
										<Field
											name="place"
											type="text"
											normalize={normalizeInteger}
											component={FormInputField}
											autoComplete="off"
											icon={PieceIcon}
											placeholder="Места"
											isWithoutErrorText
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}

				<div className="req-form__row__error">
					<Field name="weight" component={ErrorComponent} />
					<Field name="volume" component={ErrorComponent} />
					<Field name="place" component={ErrorComponent} />
				</div>
				<div className="req-form__row features">
					{features.map(({ icon, name }) => (
						<Fragment key={icon}>
							<span className="req-form__text req-form__text_right" />
							<div className="req-form__checkbox">
								<Field name={icon} component={FormCheckboxField} toggle type="checkbox" />
							</div>
							<span className="req-form__label">{name}</span>
						</Fragment>
					))}
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Примечание</span>
					<div className="req-form__field">
						<Field
							name="comment"
							type="text"
							component={FormTextAreaField}
							autoComplete="off"
							placeholder="Укажите примечание о грузе"
							width="100%"
							resize="vertical"
						/>
					</div>
				</div>
			</section>
			<section className="req-form__section">
				<div className="req-form__section-header">
					<h5 className="req-form__header-text">Информация о грузополучателе</h5>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Грузополучатель</span>
					<div
						className={`req-form__field req-form__field_${isAvailablePartners ? 'with' : 'without'}-button`}
					>
						<div className="req-form__field-wrapper">
							<Field
								name="points.1.partner.id"
								type="text"
								component={FormSelectField}
								autoComplete="off"
								placeholder="Выберите грузополучателя"
								options={partners[1] || []}
								loading={false}
								noResultsMessage="Грузополучатели не найдены"
								onChange={value => onPartnerChange(value, 1)}
								search
								onSearchChange={value => onPartnerSearchChange(value, 1)}
								searchValue={partnerSearchValue[1]}
								isWithoutSelectOnBlur
								info={info.partner[1]}
							/>
						</div>
						{isAvailablePartners && (
							<div className="req-form__add-button" onClick={() => onAddPartnerButtonClick(1)} />
						)}
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Адрес</span>
					<div
						className={`req-form__field req-form__field_${
							isAvailableAddresses ? 'with' : 'without'
						}-button`}
					>
						<div className="req-form__field-wrapper">
							<Field
								name="points.1.address.id"
								type="text"
								component={FormSelectField}
								autoComplete="off"
								placeholder="Выберите адрес"
								options={addresses[1] || []}
								loading={false}
								noResultsMessage={
									partnerSearchValue[1] ? 'Адреса не найдены' : 'Выберите грузополучателя'
								}
								onChange={value => onAddressChange(value, 1)}
								search
								onSearchChange={value => onAddressSearchChange(value, 1)}
								searchValue={addressSearchValue[1]}
								isWithoutSelectOnBlur
								info={info.address[1]}
							/>
						</div>
						{isAvailableAddresses && (
							<div className="req-form__add-button" onClick={() => onAddAddressButtonClick(1)} />
						)}
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Дата доставки</span>
					<div className="req-form__field req-form__date-field">
						<Field
							name="points.1.date"
							type="text"
							component={CustomDatePicker}
							autoComplete="off"
							onChange={value => onPointChange(value, 1, 'date')}
							placeholder=". . / . . / . ."
							dateFormat={['dd.MM.yyyy', 'dd-MM-yyyy', 'dd/MM/yyyy']}
						/>
					</div>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Контактное лицо</span>
					<div
						className={`req-form__field req-form__field_${isAvailableContacts ? 'with' : 'without'}-button`}
					>
						<div className="req-form__field-wrapper">
							<Field
								name="points.1.contact.id"
								type="text"
								component={FormSelectField}
								autoComplete="off"
								placeholder="Выберите контактное лицо"
								options={contacts[1] || []}
								loading={false}
								noResultsMessage={addressSearchValue[1] ? 'Контакты не найдены' : 'Выберите адрес'}
								onChange={value => onContactChange(value, 1)}
								search
								onSearchChange={value => onContactSearchChange(value, 1)}
								searchValue={contactSearchValue[1]}
								isWithoutSelectOnBlur
								info={info.contact[1]}
							/>
						</div>
						{isAvailableContacts && (
							<div className="req-form__add-button" onClick={() => onAddContactButtonClick(1)} />
						)}
					</div>
				</div>
				{/* Раздел временно скрыт по задаче LOG-398 */}
				{/* <FieldArray name="points.1.size" component={SizeComponent} props={{ onSizeChange, onSizeDelete }} /> */}
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right" />
					<div className="req-form__checkbox">
						<Field
							name="points.1.is_prr"
							component={FormCheckboxField}
							toggle
							type="checkbox"
							onChange={value => onPointChange(value, 1, 'is_prr')}
						/>
					</div>
					<span className="req-form__label">Требуются погрузочно разгрузочные работы (ПРР)</span>
				</div>
				<div className="req-form__row">
					<span className="req-form__text req-form__text_right">Примечание к ПРР</span>
					<div className="req-form__field">
						<Field
							name="points.1.description"
							type="text"
							component={FormTextAreaField}
							autoComplete="off"
							placeholder="Укажите примечание к ПРР"
							width="100%"
							resize="vertical"
							disabled={!isPrr.points[1]}
							onChange={e => onPointChange(e.target.value, 1, 'description')}
						/>
					</div>
				</div>
			</section>
		</div>
	);
};

RequestFormView.propTypes = {
	partners: PropTypes.shape({
		0: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.number.isRequired,
				value: PropTypes.number.isRequired,
				text: PropTypes.string.isRequired,
			}),
		),
		1: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.number.isRequired,
				value: PropTypes.number.isRequired,
				text: PropTypes.string.isRequired,
			}),
		),
	}),
	addresses: PropTypes.shape({
		0: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.number.isRequired,
				value: PropTypes.number.isRequired,
				text: PropTypes.string.isRequired,
			}),
		),
		1: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.number.isRequired,
				value: PropTypes.number.isRequired,
				text: PropTypes.string.isRequired,
			}),
		),
	}),
	contacts: PropTypes.shape({
		0: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.number.isRequired,
				value: PropTypes.number.isRequired,
				text: PropTypes.string.isRequired,
			}),
		),
		1: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.number.isRequired,
				value: PropTypes.number.isRequired,
				text: PropTypes.string.isRequired,
			}),
		),
	}),
	modes: PropTypes.array.isRequired,
	types: PropTypes.array.isRequired,
	features: PropTypes.array.isRequired,
	tenderTypes: PropTypes.array.isRequired,
	tenderCompanies: PropTypes.array.isRequired,
	rates: PropTypes.array.isRequired,
	onPartnerChange: PropTypes.func.isRequired,
	onAddressChange: PropTypes.func.isRequired,
	onContactChange: PropTypes.func.isRequired,
	isPrr: PropTypes.shape({ isPrr: PropTypes.bool, points: PropTypes.arrayOf(PropTypes.bool) }).isRequired,
	info: PropTypes.object.isRequired,
	onPartnerSearchChange: PropTypes.func.isRequired,
	onAddPartnerButtonClick: PropTypes.func.isRequired,
	onAddAddressButtonClick: PropTypes.func.isRequired,
	onAddContactButtonClick: PropTypes.func.isRequired,
	onSizeChange: PropTypes.func.isRequired,
	onSizeDelete: PropTypes.func.isRequired,
	onPointChange: PropTypes.func.isRequired,
	onAddressSearchChange: PropTypes.func.isRequired,
	partnerSearchValue: PropTypes.shape({ 0: PropTypes.string, 1: PropTypes.string }).isRequired,
	addressSearchValue: PropTypes.shape({ 0: PropTypes.string, 1: PropTypes.string }).isRequired,
	onContactSearchChange: PropTypes.func.isRequired,
	contactSearchValue: PropTypes.shape({ 0: PropTypes.string, 1: PropTypes.string }).isRequired,
	isValidationCheckboxDisabled: PropTypes.bool.isRequired,
	isToPlaces: PropTypes.bool.isRequired,
	onTypeChange: PropTypes.func.isRequired,
	isAvailablePartners: PropTypes.bool.isRequired,
	isAvailableAddresses: PropTypes.bool.isRequired,
	isAvailableContacts: PropTypes.bool.isRequired,
	isMyCarrierSelected: PropTypes.bool.isRequired,
};
