/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { getFormValues, isInvalid } from 'redux-form';
import { initialState } from './reducer';
import {
	ADD_ADDRESS_FORM_NAME,
	ADD_CONTACT_FORM_NAME,
	ADD_PARTNER_FORM_NAME,
	DATE_TIME_FORMAT,
	MY_CARRIER_ID,
	PLATFORM_ID,
	REQUEST_FORM_NAME,
} from './constants';
import { getLocalStorageItem } from '../../utils/localStorage';

export const selectRequestFormDomain = state => state.requestFormReducer || initialState;

export const selectRouterDomain = state => state.router || {};

export const selectProfileDomain = state => state.profileModalReducer || {};

export const getRequestFormInitialValuesSelector = () =>
	createSelector(
		selectRequestFormDomain,
		getFormValues(REQUEST_FORM_NAME),
		selectRouterDomain,
		({ requestInfo }, formValues, router) => {
			if (isEmpty(requestInfo) && isEmpty(formValues)) {
				return {};
			}
			if (isEmpty(requestInfo)) {
				return formValues || {};
			}
			const featuresData = {};
			if (!isEmpty(requestInfo?.features)) {
				requestInfo.features.forEach(feature => {
					featuresData[feature.icon] = true;
				});
			}

			const result = {
				...(requestInfo || {}),
				...(!isEmpty(featuresData) ? { ...featuresData } : {}),
				...(formValues?.tender_type_id !== undefined ? { tender_type_id: formValues.tender_type_id } : {}),
				...(formValues?.tender_tk_id !== undefined ? { tender_tk_id: formValues.tender_tk_id } : {}),
				...(formValues?.tender_amount !== undefined ? { tender_amount: formValues.tender_amount } : {}),
				...(formValues?.not_turn !== undefined ? { not_turn: formValues.not_turn } : {}),
				...(formValues?.fragile !== undefined ? { fragile: formValues.fragile } : {}),
				...(formValues?.not_load !== undefined ? { not_load: formValues.not_load } : {}),
				...(formValues?.comment || formValues?.comment === '' ? { comment: formValues.comment } : {}),
				...(formValues?.is_prr === undefined ? {} : { is_prr: formValues.is_prr }),
				...(router?.location.query.mode === 'add' && window.location.pathname.includes('avia')
					? { is_to_places: true }
					: { is_to_places: requestInfo.is_to_places }),
				...(formValues?.is_unchanged_fetch_at === undefined
					? {}
					: { is_unchanged_fetch_at: formValues.is_unchanged_fetch_at }),
				...(formValues?.is_validated === undefined ? {} : { is_validated: formValues.is_validated }),
				...(formValues?.is_thermo_check === undefined ? {} : { is_thermo_check: formValues.is_thermo_check }),
				...(formValues?.description || formValues?.description === ''
					? { description: formValues.description }
					: {}),
				...(formValues?.cost || formValues?.cost === '' ? { cost: formValues.cost } : {}),
				...(formValues?.place || formValues?.place === '' ? { place: formValues.place } : {}),
				...(formValues?.volume || formValues?.volume === '' ? { volume: formValues.volume } : {}),
				...(formValues?.weight || formValues?.weight === '' ? { weight: formValues.weight } : {}),
				...(formValues?.product_invoice || formValues?.product_invoice === ''
					? { product_invoice: formValues.product_invoice }
					: {}),
				...(formValues?.mode || formValues?.mode === '' ? { mode: formValues.mode } : {}),
				...(formValues?.type || formValues?.type === '' ? { type: formValues.type } : {}),
				...(formValues?.rate || formValues?.rate === '' ? { rate: formValues.rate } : {}),
				...(formValues?.points?.[0]?.time_from || formValues?.points?.[0]?.time_from === ''
					? {
							points: [
								{ ...formValues?.points[0], time_from: formValues.points[0].time_from },
								...formValues?.points.slice(1),
							],
					  }
					: {}),

				...(formValues?.points?.[0]?.time_to || formValues?.points?.[0]?.time_to === ''
					? {
							points: [
								{ ...formValues?.points[0], time_to: formValues.points[0].time_to },
								...formValues?.points.slice(1),
							],
					  }
					: {}),
			};

			if (!result.tender_type_id) {
				result.tender_type_id = PLATFORM_ID;
			}

			return result;
		},
	);

export const getPointsSelector = () =>
	createSelector(getRequestFormInitialValuesSelector(), formValues => {
		return formValues.points;
	});

export const getIsToPlacesSelector = () =>
	createSelector(getFormValues(REQUEST_FORM_NAME), formValues => {
		return Boolean(formValues?.is_to_places);
	});

export const getIsMyCarrierSelectedSelector = () =>
	createSelector(getFormValues(REQUEST_FORM_NAME), formValues => {
		return formValues?.tender_type_id === MY_CARRIER_ID;
	});

export const getDataForSaveSelector = () =>
	createSelector(
		selectRequestFormDomain,
		getRequestFormInitialValuesSelector(),
		getIsToPlacesSelector(),
		({ features }, formValues, isToPlaces) => {
			const formattedFeatures = features.reduce((acc, feature, index) => {
				if (formValues[feature.icon]) {
					acc.push(index + 1);
				}

				return acc;
			}, []);

			const result = {
				points: formValues.points?.map(point => {
					return {
						id: point?.id,
						partner_id: point.partner?.id,
						address_id: point.address?.id,
						date: point.date,
						contact_id: point.contact?.id,
						time_from: point.time_from,
						time_to: point.time_to,
						weight: point.weight || null,
						volume: point.volume || null,
						place: point.place || null,
						is_prr: point.is_prr || null,
						description: point.is_prr ? point.description : null,
						...(point?.size ? { size: point.size } : {}),
					};
				}),
				...(formValues?.size ? { size: formValues.size } : {}),
				product_invoice: formValues.product_invoice,
				type_id: formValues.type,
				mode_id: formValues.mode === 'none' ? null : formValues.mode,
				rate_id: formValues.rate,
				weight: formValues.weight || null,
				volume: formValues.volume || null,
				place: formValues.place || null,
				cost: formValues.cost,
				comment: formValues.comment,
				is_prr: formValues.is_prr,
				is_unchanged_fetch_at: formValues.is_unchanged_fetch_at,
				is_validated: formValues.is_validated,
				is_to_places: isToPlaces,
				is_thermo_check: formValues.is_thermo_check,
				// do not send description if is_prr is false
				description: formValues.is_prr ? formValues.description : null,
				features: !formattedFeatures.length ? null : formattedFeatures,
				tender_type_id: formValues.tender_type_id,
				...(formValues.tender_type_id === MY_CARRIER_ID
					? { tender_tk_id: formValues.tender_tk_id, tender_amount: formValues.tender_amount }
					: {}),
				...(formValues.id ? { id: formValues.id } : {}),
			};

			return result;
		},
	);

export const getRequestCreateRouteSelector = () =>
	createSelector(selectRequestFormDomain, ({ requestInfo }) => {
		const route = requestInfo?.routes?.[0];
		if (!route) return '';
		return route.split('/').slice(0, 3).join('/');
	});

export const getIsPartnersLoadingSelector = () =>
	createSelector(selectRequestFormDomain, ({ isPartnersLoading }) => isPartnersLoading);

export const getPartnersSelector = () => createSelector(selectRequestFormDomain, ({ partners }) => partners);

export const getPartnersOptionsSelector = () =>
	createSelector(selectRequestFormDomain, ({ partners }) => {
		return Object.values(partners).reduce((acc, partner, index) => {
			acc[index] = partner.map(item => ({
				...item,
				key: item.id,
				value: item.id,
				text: item.name,
			}));

			return acc;
		}, {});
	});

export const getPartnerSearchValueSelector = () =>
	createSelector(selectRequestFormDomain, ({ partnerSearchValue }) => partnerSearchValue);

export const getAddressesSelector = () => createSelector(selectRequestFormDomain, ({ addresses }) => addresses);

export const getAddressesOptionsSelector = () =>
	createSelector(selectRequestFormDomain, ({ addresses }) => {
		return Object.values(addresses).reduce((acc, partner, index) => {
			acc[index] = partner.map(item => ({
				...item,
				key: item.id,
				value: item.id,
				text: item.name,
			}));

			return acc;
		}, {});
	});

export const getContactsSelector = () => createSelector(selectRequestFormDomain, ({ contacts }) => contacts);

export const getContactsOptionsSelector = () =>
	createSelector(selectRequestFormDomain, ({ contacts }) => {
		return Object.values(contacts).reduce((acc, partner, index) => {
			acc[index] = partner.map(item => ({
				...item,
				key: item.id,
				value: item.id,
				text: item.name,
			}));

			return acc;
		}, {});
	});

export const getModesSelector = () => createSelector(selectRequestFormDomain, ({ modes }) => modes);

export const getTypesSelector = () => createSelector(selectRequestFormDomain, ({ types }) => types);

export const getRatesSelector = () => createSelector(selectRequestFormDomain, ({ rates }) => rates);

export const getFeaturesSelector = () => createSelector(selectRequestFormDomain, ({ features }) => features);

export const getTenderTypesSelector = () => createSelector(selectRequestFormDomain, ({ tenderTypes }) => tenderTypes);

export const getTenderCompaniesSelector = () =>
	createSelector(selectRequestFormDomain, ({ tenderCompanies }) => tenderCompanies);

export const getIsPrrSelector = () =>
	createSelector(getFormValues(REQUEST_FORM_NAME), formValues => {
		return {
			isPrr: Boolean(formValues?.is_prr),
			points: formValues?.points?.map(point => Boolean(point.is_prr)) || [],
		};
	});

export const getInfoSelector = () =>
	createSelector(getFormValues(REQUEST_FORM_NAME), formValues => {
		return {
			partner: formValues?.points?.map(point => point?.partner_info) || '',
			address: formValues?.points?.map(point => point?.address_info) || '',
			contact: formValues?.points?.map(point => point?.contact_info) || '',
			tender: formValues?.tender_info || '',
			mode: formValues?.mode_info || '',
		};
	});

export const getIsValidationCheckboxDisabledSelector = () =>
	createSelector(getFormValues(REQUEST_FORM_NAME), formValues => {
		return Boolean(formValues?.type === 3);
	});

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'request');
	});

export const getIsRequestAddFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'request' && query?.mode === 'add');
	});

export const getIsRequestInfoLoadingSelector = () =>
	createSelector(selectRequestFormDomain, ({ isRequestInfoLoading }) => isRequestInfoLoading);

export const getIsAddPartnerModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'request-partner');
	});

export const getIsAddAddressModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'request-address');
	});

export const getIsAddContactModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'request-contact');
	});

export const getPartnersFormOptionsSelector = () =>
	createSelector(selectRequestFormDomain, ({ partnersFormOptions }) =>
		partnersFormOptions.map(partner => ({
			key: `${partner.data.inn}${partner.data.kpp}`,
			value: partner.data.inn,
			ogrn: partner.data.ogrn,
			name: partner.value,
			data: {
				title: partner.value,
				address: `${partner.data?.inn || ''} ${partner.data?.address?.value || ''}`,
				deleted: partner.data?.state?.status === 'LIQUIDATED',
			},
		})),
	);

export const getPartnersFormSearchValueSelector = () =>
	createSelector(selectRequestFormDomain, ({ partnersFormSearchValue }) => partnersFormSearchValue);

export const getIsPartnersFormOptionsLoadingSelector = () =>
	createSelector(selectRequestFormDomain, ({ isPartnersFormOptionsLoading }) => isPartnersFormOptionsLoading);

export const getPartnerFormValuesSelector = () =>
	createSelector(getFormValues(ADD_PARTNER_FORM_NAME), formValues => formValues);

export const getPartnerFormIsInvalidSelector = () =>
	createSelector(isInvalid(ADD_PARTNER_FORM_NAME), isInvalid => isInvalid);

export const getAddressFormIsInvalidSelector = () =>
	createSelector(isInvalid(ADD_ADDRESS_FORM_NAME), isInvalid => isInvalid);

export const getContactFormIsInvalidSelector = () =>
	createSelector(isInvalid(ADD_CONTACT_FORM_NAME), isInvalid => isInvalid);

export const getRequestFormIsInvalidSelector = () =>
	createSelector(isInvalid(REQUEST_FORM_NAME), isInvalid => isInvalid);

export const getAddressFormOptionsSelector = () =>
	createSelector(selectRequestFormDomain, ({ addressFormOptions }) => addressFormOptions);

export const getFormattedAddressFormOptionsSelector = () =>
	createSelector(selectRequestFormDomain, ({ addressFormOptions }) => {
		return addressFormOptions.map(({ value }) => ({ key: value, text: value, value }));
	});

export const getAddressFormSearchValueSelector = () =>
	createSelector(selectRequestFormDomain, ({ addressFormSearchValue }) => addressFormSearchValue);

export const getIsAddressFormOptionsLoadingSelector = () =>
	createSelector(selectRequestFormDomain, ({ isAddressFormOptionsLoading }) => isAddressFormOptionsLoading);

export const getAddressFormValuesSelector = () =>
	createSelector(getFormValues(ADD_ADDRESS_FORM_NAME), formValues => formValues);

export const getContactFormValuesSelector = () =>
	createSelector(getFormValues(ADD_CONTACT_FORM_NAME), formValues => formValues);

export const getActivePointIndexSelector = () =>
	createSelector(selectRequestFormDomain, ({ activePointIndex }) => activePointIndex);

export const getActiveRequestIdSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => (query?.modal === 'request' ? query?.id : null));

export const getReqNameSelector = () =>
	createSelector(selectRequestFormDomain, ({ requestInfo }) => {
		return `Заявка #${requestInfo?.id || '-'} от ${
			requestInfo?.created_at ? moment(requestInfo.created_at).format(DATE_TIME_FORMAT) : '-'
		}`;
	});

export const getCompanyIdSelector = () =>
	createSelector(selectRequestFormDomain, () => {
		const companyId = getLocalStorageItem('user_data')?.companyId;

		return companyId;
	});

export const getAddressSearchValueSelector = () =>
	createSelector(selectRequestFormDomain, ({ addressSearchValue }) => addressSearchValue);

export const getContactSearchValueSelector = () =>
	createSelector(selectRequestFormDomain, ({ contactSearchValue }) => contactSearchValue);

export const getIsAvailablePartnersSelector = () =>
	createSelector(selectProfileDomain, ({ userRules }) => {
		return Boolean(userRules?.partners?.create);
	});

export const getIsAvailableAddressesSelector = () =>
	createSelector(selectProfileDomain, ({ userRules }) => {
		return Boolean(userRules?.addresses?.create);
	});

export const getIsAvailableContactsSelector = () =>
	createSelector(selectProfileDomain, ({ userRules }) => {
		return Boolean(userRules?.contacts?.create);
	});

export const getRouterQueriesSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => query || {});

export const getRequestRouteSelector = () =>
	createSelector(selectRequestFormDomain, ({ requestInfo }) => {
		return requestInfo?.routes?.[0] || '';
	});
